// action types constants
export const FINISH_FIRST_VISIT = 'cboard/App/FINISH_FIRST_VISIT';
export const DISABLE_TOUR = 'cboard/App/DISABLE_TOUR';
export const ENABLE_ALL_TOURS = 'cboard/App/ENABLE_ALL_TOURS';
export const UPDATE_CONNECTIVITY = 'cboard/App/UPDATE_CONNECTIVITY';
export const UPDATE_DISPLAY_SETTINGS = 'cboard/App/UPDATE_DISPLAY_SETTINGS';
export const UPDATE_SYMBOLS_SETTINGS = 'cboard/App/UPDATE_SYMBOLS_SETTINGS';
export const UPDATE_NAVIGATION_SETTINGS =
  'cboard/App/UPDATE_NAVIGATION_SETTINGS';
export const UPDATE_USER_DATA = 'cboard/App/UPDATE_USER_DATA';
export const SET_UNLOGGED_USER_LOCATION =
  'cboard/App/SET_UNLOGGED_USER_LOCATION';
// language constants
export const DEFAULT_LANG = 'en-US';
export const APP_LANGS = [
  'ar-SA',
  'be-BY',
  'bn-BD',
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-US',
  'en-GB',
  'es-ES',
  'fa-IR',
  'fi-FI',
  'fr-FR',
  'he-IL',
  'hi-IN',
  'hr-HR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'km-KH',
  'ko-KR',
  'mk-MK',
  'ml-IN',
  'nb-NO',
  'ne-NP',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-MD',
  'ru-RU',
  'si-LK',
  'sk-SK',
  'sq-MK',
  'sq-AL',
  'sr-ME',
  'sr-SP',
  'sr-RS',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'pt-TL',
  'uk-UA',
  'vi-VN',
  'zh-CN',
  'zu-ZA' // for crowdin contextual translation
];

export const USER_DATA_PROPERTIES = [
  'id',
  'google',
  'facebook',
  'apple',
  'name',
  'role',
  'provider',
  'locale',
  'password',
  'location',
  'email',
  'isFirstLogin',
  'birthdate',
  'lastlogin',
  'createdAt',
  'updatedAt',
  'authToken',
  'isAdmin'
];
